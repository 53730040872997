<template>
  <div>
    <CRow>
      <CCol md="12">
        <CCard>
          <CCardHeader>
            <strong>Task</strong>
          </CCardHeader>
          <CRow>
            <CCol md="8">
              <CCardBody>
                <CForm>
                  <CInput
                    label="Task *"
                    v-model="task.taskName"
                    horizontal
                    :disabled="mode.viewMode"
                    :invalid-feedback="'Please Enter the Task Name'"
                    @blur="$v.task.taskName.$touch()"
                    :is-valid="
                      $v.task.taskName.$dirty ? !$v.task.taskName.$error : null
                    "
                  />
                  <!-- <div v-if="mode.viewMode  task.fileId==!null"> -->

                  <!--  -->

                  <CInputFile
                    label="Image "
                    horizontal
                    type="file"
                    :disabled="mode.viewMode"
                    accept="image/*"
                    @change="uploadImage"
                  />
                  <!--  :is-valid="$v.task.fileId.$dirty? !$v.task.fileId.$error  : null"
                  @blur="$v.task.fileId.$touch()"
                 invalid-feedback="Please upload the Image"-->
                  <CRow>
                    <CCol md="3"></CCol>
                    <CCol md="9" align="center">
                      <CCard
                        v-if="task.fileId && !$v.task.fileId"
                        style="margin-top: 20px"
                      >
                        <CCardBody align="center">
                          <CButton
                            color="danger"
                            style="float: right"
                            @click="removeimage()"
                            :disabled="mode.viewMode"
                          >
                            <CIcon
                              name="cilXCircle"
                              description="Delete"
                            ></CIcon>
                          </CButton>
                          <CImg
                            v-if="task.fileId"
                            :src="imageUrl(task.fileId)"
                            height="180px"
                            alt="Responsive image"
                          >
                          </CImg>
                        </CCardBody>
                      </CCard>
                    </CCol>
                  </CRow>
                  <!-- </div> -->
                </CForm>
              </CCardBody>
            </CCol>
          </CRow>

          <CCardFooter align="right">
            <CButton
              v-if="mode.viewMode"
              class="mr-2"
              type="submit"
              size="sm"
              color="primary"
              @click="editMode()"
            >
              <CIcon name="cil-pencil" />Edit
            </CButton>

            <CButton
              v-if="!mode.viewMode"
              class="mr-2"
              type="submit"
              size="sm"
              color="success"
              @click="saveChecklist()"
            >
              <CIcon name="cil-check-circle" />Save
            </CButton>

            <CButton
              class="mr-2"
              type="Cancel"
              size="sm"
              color="danger"
              @click="cancel()"
            >
              <CIcon name="cil-ban" />Cancel
            </CButton>
          </CCardFooter>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import Vue from "vue";
import datetime from "vuejs-datetimepicker";
import {
  required,
  minLength,
  maxLength,
  url,
  requiredIf,
} from "vuelidate/lib/validators";
export default {
  name: "Task",
  data() {
    return {
      mode: {
        newMode: false,
        editMode: false,
        viewMode: false,
      },
      taskId: "",
      checklistId: "",
      task: {
        checklistId: "0",
        taskId: "0",
        taskName: "",
        fileId: "",
      },

      horizontal: { label: "col-3", input: "col-9" },
    };
  },

  validations: {
    task: {
      taskName: {
        required,
      },
      // fileId: {
      //          required,

      //     },
    },
  },
  created() {
    if (this.$route.params.mode == "new") {
      this.mode.newMode = true;
      this.mode.viewMode = false;
      this.mode.editMode = false;
      this.checklistId = this.$route.params.checklistId;
    }
    if (this.$route.params.mode == "view") {
      this.mode.viewMode = true;
      this.checklistId = this.$route.params.checklistId;
      this.taskId = this.$route.params.taskId;
      this.getUser();
    }
    if (this.$route.params.mode == "edit") {
      this.mode.editMode = true;
      this.checklistId = this.$route.params.checklistId;
      this.taskId = this.$route.params.taskId;
      this.getUser();
    }
  },

  methods: {
    imageUrl(fileId) {
      console.log(fileId);
      return process.env.VUE_APP_API_HOST + "/emapp/file/" + fileId;
    },

    removeimage() {
      this.task.fileId = "";
    },

    uploadImage(files, event) {
      const URL = process.env.VUE_APP_API_HOST + "/emapp/file/upload";
      console.log("uploadImage", URL);
      console.log("files", files);
      console.log("event", event);
      let fdata = new FormData();
      fdata.append("file", files[0]);

      // fdata.append("userId","uid");

      fetch(URL, {
        method: "POST",
        body: fdata,
      })
        .then((response) => response.json())
        .then((data) => {
          console.log("image upload response > ", data);
          //this.service.image = data.data.fileId;
          //this.service.image.push(data.data.fileId);
          this.task.fileId = data.data.fileId;
        });
    },
    editMode() {
      this.mode.editMode = true;
      this.mode.viewMode = false;
    },
    cancel() {
      this.$router.push({
        name: "Tasks",
        params: {
          checklistId: this.$route.params.checklistId,
          modelId: this.$route.params.modelId,
          subGroupId: this.$route.params.subGroupId,
          groupId: this.$route.params.groupId,
        },
      });
    },

    saveChecklist() {
      this.$v.task.$touch();
      if (this.$v.task.$invalid) {
        console.log("form is invalid", JSON.stringify(this.$v.task));
      } else {
        // fetch(
        //   process.env.VUE_APP_API_HOST +
        //     "/emapp/web/save/task/" +
        //     this.checklistId,
        //   {
        //     method: "POST",
        //     headers: {
        //       "Content-type": "application/json",
        //     },
        //     body: JSON.stringify(this.task),
        //   }
        // );
        this.apiPostSecure(
          process.env.VUE_APP_API_HOST +
            "/emapp/web/secure/save/task/" +
            this.checklistId,
          this.task
        ).then((task) => {
          console.log(JSON.stringify(task));

          this.$router.push({
            name: "Tasks",
            params: {
              checklistId: this.$route.params.checklistId,
              modelId: this.$route.params.modelId,
              subGroupId: this.$route.params.subGroupId,
              groupId: this.$route.params.groupId,
            },
          });
        });
      }
    },
    validator(val) {
      return val ? val.length >= 4 : false;
    },

    getUser() {
      this.apiGetSecure(
        process.env.VUE_APP_API_HOST +
          "/emapp/web/secure/get/task/" +
          this.taskId
      )
        .then((response) => response.json())
        .then((data) => (this.task = data));
    },
  },
  mounted() {},
};
</script>
